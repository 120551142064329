<template>
	<div class="stats">
		<h2 class="stats-title">Overview</h2>
		<div class="stats-container">
			<stats-card
				:quantity="incompletePages.total"
				title="Total Pages"
				paging
				sub-title="Incomplete Pages"
				:is-active="isActive('paging')"
				@click="toggleActive('paging')"
			/>
		</div>
	</div>
</template>

<script>
import StatsCard from '@/components/StatsCard'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		StatsCard,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		activePageFilterId() {
			if (this.pagesFilters.statuses.length === 1) {
				return 'paging'
			}
			return ''
		},
		...mapGetters({
			partner: 'partner',
			types: 'callTypes',
		}),

		...mapState({
			incompletePages: state => state.pages.incomplete,
			pagesFilters: state => state.pages.filters,
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Fetch the paging count.
		 *
		 * @return {void}
		 */
		async fetchPagingCount() {
			if (!this.partner || !this.partner.id) {
				return
			}
			await this.getIncompletePagingCount()
		},

		isActive(cardId) {
			return this.activePageFilterId === cardId
		},

		toggleActive(cardId) {
			this.activeCard = this.activeCard === cardId ? null : cardId
		},

		...mapActions('pages', ['getIncompletePagingCount']),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Index',

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Active partner watcher.
		 *
		 * @type {Object}
		 */
		partner: {
			handler: 'fetchPagingCount',
			immediate: true,
		},
	},

	data() {
		return {
			activeCard: null,
		}
	},
}
</script>
